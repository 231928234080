<template>
    <div class="collection">
        <vendor-top noBootom :type.sync="type" :searchKey.sync="searchKey" @submitSearch="submitSearch">
            <template slot="button">
                <el-button type="warning" size="small" @click="$router.push('/vendor/caseEdit')">{{
                    $t('xin-zeng-an-li')
                }}</el-button>
            </template>

            <!-- <template slot="bottom-left">
                <el-button :type="type === 'NEWS' ? 'warning' : 'info'" size="small" plain @click="type = 'NEWS'"
                    >新闻</el-button
                >
                <el-button :type="type === 'CASE' ? 'warning' : 'info'" size="small" plain @click="type = 'CASE'"
                    >案例</el-button
                >
            </template> -->
        </vendor-top>

        <div class="el-main" v-loading="loading">
            <!-- <div class="mainList" ref="list" v-if="type == 'NEWS'">
                <news-row :info="item" v-for="(item, index) in list" :key="item.id" :first="index === 0"></news-row>
            </div> -->

            <div class="mainList" ref="list">
                <case-row :info="item" @move="move" @del="del" v-for="item in list" :key="item.id"></case-row>
            </div>
            <empty-page v-if="empty">
                <img slot="img" :src="require('@/assets/kong_img_default.png')" />
                <span slot="text">{{ $t('zan-shi-mei-you-an-lio') }}</span>
            </empty-page>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableList from '../../mixins/pageableList';
import vendorTop from '../../components/vendor/Top';
import NewsRow from '../../components/vendor/NewsRow';
import CaseRow from '../../components/vendor/CaseRow';
import { collectType } from '../../utils/AppState';

export default {
    name: 'case',
    mixins: [pageableList],
    data() {
        return {
            url: '/successCase/getVendorNews',
            httpType: 'get',
            chooseList: [],
            isEdit: false,
            menus: [],
            customCateId: '',
            status: 0,
            noPage: true,
            type: '',
            urlInfo: {
                NEWS: '/successCase/getVendorNews',
                CASE: '/successCase/getVendorCase'
            }
        };
    },
    computed: {
        ...mapState(['buyersInfo']),
        listQuery() {
            return {
                vendorInfoId: this.buyersInfo.vendorId
            };
        },
        pageQuery() {
            return {
                type: this.type
            };
        }
    },
    watch: {
        listQuery() {
            this.page=1;
            this.getData();
        },
        type() {
            this.url = this.urlInfo[this.type];
            this.getData();
        }
    },
    mounted() {
        this.type = this.$route.query.type || 'CASE';
    },
    components: {
        vendorTop,
        NewsRow,
        CaseRow
    },
    methods: {
        move(info) {
            const list = [...this.list];
            const start = list.findIndex(item => {
                return info.id === item.id;
            });
            const end = info.direction + start;
            this.loading = true;
            Promise.all([
                this.$http.post(
                    '/successCase/save',
                    {
                        ...list[start],
                        sort: list[end].sort
                    },
                    { body: 'json' }
                ),
                this.$http.post(
                    '/successCase/save',
                    {
                        ...list[end],
                        sort: list[start].sort
                    },
                    { body: 'json' }
                )
            ])
                .then(_ => {
                    this.$message.success(
                        info.direction > 0 ? this.$t('xia-yi-cheng-gong') : this.$t('shang-yi-cheng-gong')
                    );
                    this.getData();
                })
                .catch(e => {
                    console.log(e);
                    this.loading = false;
                    this.$message.error(e.error);
                });
        },
        del(id) {
            this.$alert(this.$t('shan-chu-jiang-wu-fa-hui-fu-que-ren-yao-shan-chu-mo'), this.$t('jing-gao'), {
                type: 'error'
            })
                .then(() => {
                    return this.$http.post(`/successCase/del/${id}`);
                })
                .then(() => {
                    this.$message.success(this.$t('shan-chu-cheng-gong'));
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error(e.error);
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/pagebleTable';
@import '../../styles/variables';

.route {
    background-color: transparent !important;
}

.el-main {
    margin: 0 0 0;
    // min-height: calc(100% - 136px);
    overflow: hidden;
    background-color: #fff;
}
</style>
<style lang="scss">
.el-radio-group {
    .el-radio-button__inner {
        border-radius: 0px !important;
    }
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #ffa526;
        border-color: #ffa526;
        box-shadow: -1px 0 0 0 #ffa526;
    }
}
</style>
